/*! 2025-4-2 20:17:05 */
[data-v-4287d15e]:export{menu-color:rgba(255,255,255,0.95);menu-color-active:rgba(255,255,255,0.95);menu-background:#282c34;column-second-menu-background:#fff}.pagination-container[data-v-4287d15e]{background-color:#fff;padding:20px 10px;text-align:right;padding-right:60px}.pagination-container.hidden[data-v-4287d15e]{display:none}.pagination-container.left[data-v-4287d15e]{text-align:left}

[data-v-7bb85977]:export{menu-color:rgba(255,255,255,0.95);menu-color-active:rgba(255,255,255,0.95);menu-background:#282c34;column-second-menu-background:#fff}.footer .el-button+.el-button[data-v-7bb85977]{margin-left:30px}.ml15[data-v-7bb85977]{margin-left:15px}

[data-v-2eca4a86]:export{menu-color:rgba(255,255,255,0.95);menu-color-active:rgba(255,255,255,0.95);menu-background:#282c34;column-second-menu-background:#fff}.inspection-result-wrapper[data-v-2eca4a86]{display:inline-block;text-align:right;font-size:12px;font-weight:600}.inspection-result-wrapper .label[data-v-2eca4a86]{color:#606266;margin-right:4px}.inspection-result-wrapper .count[data-v-2eca4a86]{color:#606266;margin-right:10px}

[data-v-645b2e1e]:export{menu-color:rgba(255,255,255,0.95);menu-color-active:rgba(255,255,255,0.95);menu-background:#282c34;column-second-menu-background:#fff}.h3-wrapper[data-v-645b2e1e]{display:flex}.h3-wrapper .inspection-result[data-v-645b2e1e]{flex:1}.page-header[data-v-645b2e1e]{height:33px}[data-v-645b2e1e] .page-header .el-form-item{margin-bottom:0}.divider[data-v-645b2e1e]{height:1px;background-color:#f5f7fa;margin:9px 0 15px 0}.mb30[data-v-645b2e1e]{margin-bottom:30px}.mt30[data-v-645b2e1e]{margin-top:30px}.mb20[data-v-645b2e1e]{margin-bottom:20px}.mt20[data-v-645b2e1e]{margin-top:20px}.mb15[data-v-645b2e1e]{margin-bottom:15px}.mt15[data-v-645b2e1e]{margin-top:15px}.table-container[data-v-645b2e1e]{padding:20px 15px !important}.operation[data-v-645b2e1e]{display:flex;justify-content:space-evenly}.operation i[data-v-645b2e1e]{font-size:20px}.pointer[data-v-645b2e1e]{cursor:pointer}.error-info[data-v-645b2e1e]{color:#ff4d4f;font-size:12px;line-height:1;position:relative;padding-top:5px}[data-v-645b2e1e] .el-input-number--small{width:100px !important}[data-v-645b2e1e] .el-input-number--small .el-input__inner{padding:0 10px !important}[data-v-645b2e1e] .el-input-number--small .el-input-number__increase,[data-v-645b2e1e] .el-input-number--small .el-input-number__decrease{display:none !important}[data-v-645b2e1e] .el-input-number--small.error .el-input__inner{border-color:#ff4d4f}


.inbound .el-select-dropdown__item {
  width: 200px;
}
.el-tooltip__popper {
  max-width: 400px;
}


/*# sourceMappingURL=chunk-7a4f504e.e3b2a8f3.css.map*/